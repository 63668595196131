
<template>
  <div class="productService">
    <div class="product-topImg"></div>

    <div class="case center">
      <el-card style="margin-top: 20px">
        <div class="case-items">
          <div class="content-left">
            <div class="case-title">
              <p>瑞懿工业地价查询分析系统</p>
              <p></p>
            </div>
            <div class="case-synopsis">
              主要使用人员与场景：银行、企事业单位、房企等。
            </div>
            <div class="case-word">
              项目简介：针对银行等金融机构目前面临的工业用地评估样本少、类型单一、信息真实度低、押品审查重测难度大等问题，瑞懿利用自身数据优势，聚合工业用地一二级市场样本、工业园区在内的多维数据，依托丰富的专业知识及空间分析技术，搭建集工业用地评估样本查询、工业用地市场动态监测、统计分析、可视化展现为一体的集合平台。
            </div>
            <div class="case-more">更多介绍</div>
          </div>
          <div class="content-right1"></div>
        </div>
      </el-card>
      <el-card style="margin-top: 20px">
        <div class="case-items">
          <div class="content-right2"></div>
          <div class="content-left">
            <div class="case-title">
              <p>房地产企业综合分析信息服务平台</p>
              <p></p>
            </div>
            <div class="case-synopsis">
              主要使用人员与场景：银行、企事业单位、房企等。
            </div>
            <div class="case-word">
              项目简介：针对银行总行在房开贷业务开展的过程中遇到的土地信息不对称、对房企定量考评困难、主导业务方向难度大、贷后监管困难等问题，瑞懿利用房企图谱、土储、财报等数据形成企业拿地布局、经营情况、运行策略、发展态势等多维度房企画像，同时利用土地交易数据结合舆情监控分析房地产市场整体走势。为银行等金融机构在贷前精准了解客户，辅助获客、降低风险，在贷中贷后动态追踪项目进展，提升管理效率，提供全流程的风控方案。
            </div>
            <div class="case-more" style="margin-top:85px">更多介绍</div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.productService {
  width: 100%;
}
.product-topImg {
  width: 100%;
  height: 308px;
   background-image: url('../../assets/image/产品服务.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.case {
  padding: 70px 0px 180px 0px;
}
.case-items {
  display: flex;
  justify-content: space-between;
}
.content-left {
  width: 45%;
}

.case-title {
  width: 100%;
}
.case-title p {
  display: block;
}
.case-title p:nth-child(1) {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.case-title p:nth-child(2) {
  width: 32px;
  height: 6px;
  background: #00143d;
  margin-top: 18px;
}
.case-synopsis {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  margin-top: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.case-word {
  width: 100%;
  height: 96px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #666666;
  margin-top: 26px;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden; */
}

.content-right1 {
  width: 47%;
  margin-right: 44px;
  background-image: url("../../assets/image/case1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.content-right2 {
  width: 47%;
  margin-right: 44px;
  background-image: url("../../assets/image/case2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.case-more{
  width: 109px;
  height: 31px;
  text-align: center;
  font-size: 14px;
  line-height: 29px;
  color: #999999;
  border: 1px solid #707070;
  border-radius: 30px;
  margin-top: 40px;
  margin-bottom: 26px;
  cursor: pointer;
}
.case-more:hover {
  color: #e58f8f;
   border: 1px solid #e58f8f;
}
</style>